import { ADMIN_ROLES } from "@/utils/constants";
import { DrawerItem } from "./drawerItem";

class User {
  uid: string;
  hashedUserId: string;
  supportId: string;
  name: string;
  email: string;
  photoURL: string;
  disabled: boolean;
  roles: string[];
  creationTime: Date;
  lastSignInTime: Date;
  providers: string[];
  customClaims: any;

  constructor(obj: any, claims?: any) {
    this.uid = obj.uid;
    this.hashedUserId = obj.hashedUserId;
    this.supportId = obj.supportId;
    this.name = obj.displayName;
    this.email = obj.email;
    this.photoURL = obj.photoURL;
    this.disabled = obj.disabled;
    this.roles = [];
    this.customClaims = claims ?? obj.customClaims;
    if (this.customClaims && this.customClaims.snkn_privileges) {
      this.roles = this.customClaims.snkn_privileges;
    }
    this.creationTime = new Date(obj.metadata.creationTime);
    this.lastSignInTime = new Date(obj.metadata.lastSignInTime);
    this.providers = obj.providerData.map((element: any) => {
      return element.providerId;
    });
  }

  drawerItems(): DrawerItem[] {
    const home = new DrawerItem("ホーム", "/", "mdi-home");
    const admins = new DrawerItem("管理者一覧", "/admins", "mdi-crown");
    const users = new DrawerItem("ユーザー一覧", "/users", "mdi-account-group");
    const facilities = new DrawerItem(
      "温浴施設データ一覧",
      "/facilities",
      "mdi-database"
    );
    const facilityTags = new DrawerItem(
      "温浴施設タグ一覧",
      "/facility-tags",
      "mdi-tag-text"
    );
    const facilityRequests = new DrawerItem(
      "温浴施設修正依頼一覧",
      "/facility-reports",
      "mdi-table-edit"
    );
    const co2devices = new DrawerItem(
      "CO2デバイス一覧",
      "/co2devices",
      "mdi-router-wireless"
    );
    const notices = new DrawerItem("お知らせ一覧", "/notices", "mdi-bullhorn");
    const saunaSessions = new DrawerItem(
      "サウナセッション一覧",
      "/sauna-sessions",
      "mdi-heart-outline"
    );
    const seasons = new DrawerItem("シーズン一覧", "/seasons", "mdi-leaf");
    const competitions = new DrawerItem(
      "トトノイ-１一覧",
      "/competitions",
      "mdi-trophy"
    );
    const webstore = new DrawerItem(
      "ウェブストアアイテム一覧",
      "/webstore",
      "mdi-store"
    )
    const notificationTests = new DrawerItem(
      "通知テスト",
      "/notification-tests",
      "mdi-bell"
    );
    const bigquery = new DrawerItem(
      "BigQuery",
      "/bigquery",
      "mdi-table"
    );
    const batches = new DrawerItem(
      "バッチ処理一覧",
      "/batches",
      "mdi-database-cog-outline"
    );
    const targetRoles: Set<DrawerItem> = new Set();
    if (this.roles.includes(ADMIN_ROLES.admin)) {
       [
        home,
        admins,
        users,
        facilities,
        facilityTags,
        facilityRequests,
        co2devices,
        notices,
        saunaSessions,
        seasons,
        competitions,
        webstore,
        notificationTests,
        bigquery,
        batches,
      ].forEach(targetRoles.add, targetRoles)
    }
    if (this.roles.includes(ADMIN_ROLES.manager)) {
      [
        home,
        users,
        facilities,
        facilityTags,
        facilityRequests,
        co2devices,
        notices,
        saunaSessions,
        competitions,
        webstore,
        notificationTests,
      ].forEach(targetRoles.add, targetRoles);
    }
    if (this.roles.includes(ADMIN_ROLES.facility_manager)) {
      [
        home,
        facilities,
        facilityTags,
        facilityRequests
      ].forEach(targetRoles.add, targetRoles);
    }
    if (this.roles.includes(ADMIN_ROLES.facility_editor)) {
      [home, facilities].forEach(targetRoles.add, targetRoles);
    }
    if (this.roles.includes(ADMIN_ROLES.analyst)) {
      [home, users, co2devices, saunaSessions].forEach(targetRoles.add, targetRoles);
    }
    if (this.roles.includes(ADMIN_ROLES.customer_service)) {
      [home, users, notices].forEach(targetRoles.add, targetRoles);
    }
    if (this.roles.includes(ADMIN_ROLES.client)) {
      [home, co2devices].forEach(targetRoles.add, targetRoles);
    }
    [home].forEach(targetRoles.add, targetRoles);
    return Array.from(targetRoles);
  }
}

class UsersGetResponse {
  users: User[];
  pageToken: string | undefined;

  constructor(users: User[] = [], pageToken: string | undefined = undefined) {
    this.users = users;
    this.pageToken = pageToken;
  }
}

export { User, UsersGetResponse };
